@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Roboto+Condensed:wght@700&display=swap');

body {
  background-color: #f9f9f9;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a{
  color: #00bace;
}
a:hover{
  color: #00ffff;
}

a.comune{
  color:#212529;
}
a.comune:hover{
  color:#434343;
}

h1,.h1{
  font-family: 'Roboto Condensed', sans-serif;
  color: #333
}

main{
  display: flex;
  flex-direction: column;
  min-height: 93vh;
}

footer {
  display:flex;
  min-height: 200px;
  background-color: #434343;
  align-items: center;
}

button{
  border:none;
  padding: 5px;
  min-width: 120px;
  border-radius: 5px;
}

.card{
  min-height:130px;
}

.info{
  cursor: help;
}

.tooltip {
  width: 40ch;
  max-width: 80vw;
}

th span{
  cursor: pointer;
  user-select:none;
}

.slick-next:before, .slick-prev:before{
  display: none;
  color: #434343;
}

.slick-next:before{
  margin-left: 80px;
}
.slick-prev:before{
  margin-right: 80px;
}

@media (min-width: 500px) {
  abbr {
    text-decoration: none!important;
    cursor: pointer!important;
  }
  abbr span {
    display: none;
  }
  abbr::before {
    content: attr(title);
  }
  .slick-next:before, .slick-prev:before{
    display: block;
  }
}
.modal-dialog {
  width: 1024px;
  max-width: 90vw;
}

table {
  border-collapse: collapse;
}

tr {
  border-bottom: 1pt solid lightgray;
}